'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.controller:MundoReportingCtrl

 # @description

###
class MundoReportingCtrl
  ### @ngInject ###
  constructor:(
    $scope
    $log
    $filter
    $window
    $timeout
    $mdDialog
    $mdSidenav
    $rootScope
    $stateParams
    $location
    ReportSourceManager
    ReportConfigurationManager
    ReportConfigurationService
    ReportFilterService
    ReportTabService
    ColorService
    ReportVisualizersManager
    ReportTemplateManager
    _
    tmhDynamicLocale
    $templateRequest
    MundoNavigation
    UiHelpers
    uuid4
    ReportTemplateService
    hotkeys
  ) ->
    tmhDynamicLocale.set('nl-be')

    @tabs = []
    @selected = null
    @previous = null
    @selectedIndex = $scope.selectedIndex = 0
    @reportSources = []
    @reportVisualizers = []
    @reportConfigurations = []
    @loadConfigurationsStarted = false
    @configurationsToLoadCount = 0
    @isOpen = false

    ## -----------------------------
    ## INITIALISATION OF THE PAGE
    ## -----------

    @init = () =>
      ## Load the possible reports
      ReportSourceManager.getList().then (reportSources) =>
        @reportSources = reportSources
        @updateNavigation()

      ## Get the active configurations and templates as well
      @loadConfigurations()

      ## Load the templates. As in actually the jade templates. Not the reporting templates
      @loadTemplateFiles()

      ReportVisualizersManager.getList().then (data) =>
        @reportVisualizers = data

    ## Load all opened, saved or template configurations
    @loadConfigurations = () =>
      # Set the fact that the loading has started. This
      @loadConfigurationsStarted = true
      ReportConfigurationService.getListWithParams
        limit: 99
        'filter[]': 'OR,pinned,true,opened,true,template,true'
      .then (results) =>
        @reportConfigurations = results.plain()
        filteredConfigurations = results.filter (config) ->
          return config.opened and not config.parent and not config.template
        angular.forEach filteredConfigurations, (config) =>
          @openConfiguration config


    $rootScope.$on 'createdTemplate', () =>
      @loadConfigurations()

    $scope.$on 'openConfiguration', (event, args) =>
      @openConfiguration args['config']

    ## Goes and load the neccesary html files and stuff
    @loadTemplateFiles = () =>
      types = _.union.apply _.union, (@reportSources.map (v) -> (x.type for k, x of v.fields when x.options.field))
      for part in ['multiple', 'single', 'element']
        for type in types
          $templateRequest("mundo-reporting/views/table/field/#{type}/#{part}.tpl.html")

    ## Renders the sidebar navigation. Might remove this in the future, but keeping it for now
    @updateNavigation = () =>
      @navSection = MundoNavigation.sections['reporting']
      showExpression = 'reportingActive'

      if @navSection.original?
        @navSection.items = angular.copy @navSection.original.items
      else
        @navSection.original = angular.copy @navSection

      @navSection.items.push
        label: 'app.report.new-report'
        showExpression: showExpression
        header: true

      for source in @reportSources
        @navSection.items.push
          label: "messages.report-name.#{source.label}"
          showExpression: showExpression
          data:
            source: source
          click: (v) =>
            @addNewTab v.data.source

      @navSection.items.push
        label: 'app.report.saved-reports'
        showExpression: showExpression
        header: true

      for config in @reportConfigurations when ((not config.opened) and (config.pinned))
        @navSection.items.push
          label: config.label
          showExpression: showExpression
          translate: false
          data:
            config: config
          click: (v) =>
            @openConfiguration v.data.config

    @openConfiguration = (config) =>
      @setConfigurationOpened config, true
      @createConfiguration config
      @updateNavigation()
      @goToTab @tabs.length

    @createConfiguration = (config) =>
      source = @reportSources.filter (v) -> v.code == config.source

      if source? and source.length
        source = angular.copy source[0]
        source['configuration'] = config

        tab = @createNewTab source
        @tabs.push tab

    @removeConfigurationFromBackend = (t = null) =>
      @currentRemovalTab = t
      # Show Dialog
      $mdDialog.show
        templateUrl: 'mundo-reporting/views/dialogs/report-remove-dialog.modal.tpl.html'
        controller: =>
          this.parent = @
        controllerAs: 'parentCtrl'
        clickOutsideToClose: false

    # Create the tab object.
    @createNewTab = (reportSource, visualizeFilters = null) ->
      ReportTabService.createNewTab reportSource, visualizeFilters

    # Method to add a new tab from the interface.
    @addNewTab = (reportSource, visualizeFilters = null) =>
      # Creation of new tab with selected source.
      newTab = @createNewTab reportSource, visualizeFilters

      # Add tab to @tabs-array.
      @tabs.push newTab
      @goToTab @tabs.length

    # Adding a listener-method to replace a config
    $scope.$on 'replaceConfigWithUpdatedConfig', (event, args) =>

      # Classic stop of the propagation of the emit.
      event.stopPropagation()
      $log.debug 'replacing old configuration', args
      # Replace a config
      reportConfigs = _.filter @reportConfigurations, (rc) ->
        args['config']._id == rc._id

      if reportConfigs && reportConfigs.length > 0
        index = @reportConfigurations.indexOf reportConfigs[0]

        @reportConfigurations[index] = args['config']
        @updateNavigation()

    # Adding a listener-method to add a config to a certain tab.
    $scope.$on 'addConfigToTabEmit', (event, args) =>

      # Classic stop of the propagation of the emit.
      event.stopPropagation()

      # Add config to the tab with args.
      @addConfigToTab args

    $rootScope.$on 'configConversion', (event, args) =>
      config = args.configuration
      @closeConfigTab config
      @createTemplateFromConfiguration config

    # Add config to the tab with the right arguments.
    @addConfigToTab = (args) =>

      # Get the index of the current tab.
      index = @tabs.indexOf args['source']

      # Add the config to the configurations-array and set the 'reportIsOpen' parameter to true
      configOfTab = args['config']
      configOfTab.opened = true
      @reportConfigurations.push configOfTab
      @updateNavigation()

      # Create a 'configuration'-element of the tab.
      @tabs[index]['configuration'] = configOfTab

    @removeConfiguration = (tab) =>
      UiHelpers.openDialog
        template: 'mundo-reporting/views/dialogs/report-remove-dialog.modal.tpl.html'
        title: 'app.report.dialog-remove-title'
        onConfirm: () =>
          tabIndex = @tabs.indexOf tab
          @tabs.splice tabIndex, 1

          # Mark report configuration ID as deleted
          for key, config of @reportConfigurations when config._id == tab.configuration._id
            ReportConfigurationService.removeConfiguration config._id
            @reportConfigurations.splice key, 1

          @updateNavigation()

    @setConfigurationOpened = (reportConfiguration, opened) ->
      reportConfiguration.opened = opened
      ReportConfigurationService.patchConfiguration reportConfiguration, {opened: opened}

    ##
    ## TAB STUFF
    ##

    # Set focus on a specific tab
    @goToTab = (index) =>
      @selectedIndex = index

    # Set focus on a specific tab
    @nextTab = () =>
      tabCount = @tabs.length
      if @selectedIndex == tabCount
        @selectedIndex = 0
      else
        @selectedIndex++

    # Set focus on a specific tab
    @prevTab = () =>
      tabCount = @tabs.length
      if @selectedIndex == 0
        @selectedIndex = tabCount
      else
        @selectedIndex--

    @closeTab = (tab) =>
      tabIndex = @tabs.indexOf tab

      # Mark report configuration ID as closed
      for config in @reportConfigurations when config._id == tab.configuration._id
        @setConfigurationOpened config, false

      @tabs.splice tabIndex, 1
      @updateNavigation()

    # Close a tab when you only know which configuration it is
    @closeConfigTab = (config) =>
      tabIndex = _.findIndex @tabs, (t) ->
        t.configuration.id == config._id

      # Mark report configuration ID as closed
      @setConfigurationOpened config, false
      @tabs.splice tabIndex, 1

    @closeAllTabs = () =>
      for config in @reportConfigurations
        @setConfigurationOpened config, false
      @tabs = []
      @updateNavigation()

    ##
    ## TEMPLATE STUFF
    ##

    @openTemplate = (template) =>
      # Open a template
      # 1. When the template has defined parameters, show a popup first
      # 2. Instantiate the template and apply the values from the dialog to the configuration
      # 3. Open the configuration
      #
      # - If the template has no parameters, just instantiate and open the configuration

      source = @reportSources.filter (v) -> v.code == template.source

      if(template.userData?)

        if(angular.isString template.userData)
          template.userData = JSON.parse template.userData

        if template.userData.tplParameters?

          ReportTemplateService.openTemplateDialog(template, source[0])
            .then (result) =>

              # Okay so from the dialog we get an array of filters.
              # We have to transpose the resulting filter values and operands on the filter values of

              # Loop over the resulting filters and put the
              angular.forEach result, (filter) ->
                template.filters[filter.index].value = filter.active.value
                template.filters[filter.index].operand = filter.active.operand

              # Store the filters in a neat new array
              finalFilters = angular.copy template.filters

              # Loop over all final filters to delete the values that we cannot send
              # to the api.
              angular.forEach finalFilters, (f) ->
                delete f.locked

              # Instantiating the template
              ReportTemplateManager.instantiate template
                .then (configuration) =>

                  # We got a configuration but have to patch it immediately
                  ReportConfigurationService.patchConfiguration configuration, { filters: finalFilters }
                    .then (config) =>
                      ReportConfigurationManager.one(configuration._id).get()
                        .then (cfg) =>
                          @reportConfigurations.push cfg
                          @openConfiguration cfg
        else
          ReportTemplateManager.instantiate template
            .then (cfg) =>
              @reportConfigurations.push cfg
              @openConfiguration cfg

    @createTemplateFromConfiguration = (configuration) =>
      source = @reportSources.filter (v) -> v.code == configuration.source

      # This function emits an even on the rootscope called: $rootScope.$emit "createdTemplate"
      ReportTemplateService.createTemplateFromConfiguration source[0], configuration

    @updateTemplate = (configuration) =>
      source = @reportSources.filter (v) -> v.code == configuration.source
      ReportTemplateService.updateTemplate source[0], configuration

    # Call that is executed when the
    @deleteTemplate = (configuration) =>
      ReportTemplateManager.remove(configuration).then () =>
        @loadConfigurations()

    # Add hotkeys
    hotkeys.add
      combo: 'c a t'
      description: 'Close all tabs'
      callback: () =>
        @closeAllTabs()

    hotkeys.add
      combo: 'c t'
      description: 'Close current tab'
      callback: () =>
        @closeTab @tabs[@selectedIndex]

    hotkeys.add
      combo: 't n'
      description: 'Next Tab'
      callback: () =>
        @nextTab()

    hotkeys.add
      combo: 't p'
      description: 'Previous Tab'
      callback: () =>
        @prevTab()


    @init()

angular
  .module('mundoReporting')
  .controller 'MundoReportingCtrl', MundoReportingCtrl
